@import "../../../Styles/Typography.css";

.header-container {
  background-color: white;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 2000;
}

.header-container-transparent {
  background-color: transparent;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
}

.logo-container {
  flex: 1;
  padding: 20px 0px;
}

.nav-menu-container {
  display: flex;
  flex: 1;
  padding: 40px 0px;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu-container li {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #292826;
  padding-bottom: 6px;
  cursor: pointer;
}

.nav-menu-container-home {
  display: flex;
  flex: 1;
  padding: 40px 0px;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu-container-home li {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: white;
  padding-bottom: 6px;
  cursor: pointer;
}

.underlined-nav {
  border-bottom: 2px #292826 solid;
}

.account-bag-container {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 40px 0px;
  justify-content: flex-end;
}

.account-logo-wrap {
  margin-right: 30px;
}

.cart-bag-count {
  font-family: "Open Sans", sans-serif;
  width: 16px;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  margin-left: 10px;
  margin-bottom: 5px;
  line-height: 18px;
  text-align: center;
  color: #292826;
  cursor: pointer;
}

.cart-bag-count-white {
  font-family: "Open Sans", sans-serif;
  width: 16px;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  margin-left: 10px;
  margin-bottom: 5px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
