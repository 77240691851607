.product-image-carousel-thumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.thumb-inactive {
  width: 75px;
  height: 75px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  margin: 0px 20px 20px 0px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.thumb-inactive:hover {
  opacity: 0.8;
}

.thumb-active {
  width: 75px;
  height: 75px;
  border: 2px solid #602714;
  box-sizing: border-box;
  margin: 0px 20px 20px 0px;
}
