.static-modal-overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10%;
  z-index: 10000;
}

.static-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 5px;
  outline: none;
  width: 80%;
  display: flex;
  flex-direction: column;
  height: 80%;
}

.static-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 15px;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0px 0px;
  position: sticky;
  top: 0px;
  background-color: white;
}

.static-modal-header h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #292826;
  text-transform: capitalize;
}

.static-modal-main {
  padding: 15px 25px;
  font-family: "Open Sans", sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.static-modal-main::-webkit-scrollbar {
  background-color: white;
  border-bottom-right-radius: 5px;
  width: 10px;
}

.static-modal-main::-webkit-scrollbar-track {
  border-left: 1px solid #ebebeb;
}

.static-modal-main::-webkit-scrollbar-thumb {
  background: #7c7c7c;
  border-radius: 30px;
  width: 10px;
}

.static-modal-main p {
  margin: 0px;
}
