.certifications-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 50px;
}

.certifications-main-container p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #292826;
  width: 75%;
  margin-bottom: 50px;
}

.certificate-container {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e3e1;
  box-sizing: border-box;
  width: 85%;
  margin-bottom: 30px;
}

.certificate-container img {
  width: 200px;
  height: auto;
}

.certificate-container span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #292826;
}

.certificate-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  text-align: left;
}
