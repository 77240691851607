.mission-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  background-color: #f8efdd;
}

.mission-container h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.missions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.mission {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0px 50px;
}

.mission img {
  width: 80px;
  height: auto;
}

.mission h5 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292826;
  margin: 20px 0px 10px;
}

.mission p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px;
}
