.order-history-container {
  display: flex;
  flex-direction: column;
  flex: 5;
  padding: 50px;
}

.order-history-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.order-history-table {
  display: flex;
  flex-direction: column;
}

.order-history-table tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff;
  padding-block: 20px;
}

.order-history-table th {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  text-align: start;
  flex: 1;
}

.order-history-table th:nth-last-child(1) {
  text-align: end;
}

.order-history-table td {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #292826;
  flex: 1;
}

.order-history-table td:nth-last-child(1) {
  text-align: end;
}

.order-history-table td:nth-last-child(1) span {
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid #292826;
  cursor: pointer;
}
