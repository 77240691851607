.faq-main-container {
  display: flex;
  padding: 20px 20px 50px;
}

.faq-main-left-panel-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px;
}
.faq-main-left-panel-container div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  cursor: pointer;
}

.faq-section-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #78736a;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
}

.faq-section-title-black {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
}

.faq-main-content-container {
  display: flex;
  flex-direction: column;
  flex: 5;
  margin-inline: 50px;
}

.faq-main-content-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
  text-transform: capitalize;
}

.faq-ques-ans-holder {
  display: flex;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding-block: 20px;
}

.faq-ques-ans-holder div {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.faq-ques {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #292826;
  text-transform: uppercase;
  cursor: pointer;
}

.faq-ans {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #78736a;
  margin-top: 10px;
}
