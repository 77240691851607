.blog-carousel-container {
  flex: 1;
  display: flex;
}

.blog-carousel-item {
  display: flex;
  align-items: center;
  align-items: stretch;
  justify-content: space-between;
}

.blog-carousel-cover-image-container {
  flex: 1;
  display: flex;
  position: relative;
}

.blog-carousel-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-carousel-content {
  flex: 1;
  background: #f1e9f3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
}

.blog-carousel-content h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
  text-align: left;
  margin: 0px;
}

.blog-carousel-content h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  text-align: left;
  margin: 20px 0px;
}

.blog-carousel-content p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  text-align: left;
  margin: 0px;
}

.learn-more-btn-container {
  margin-top: 30px;
}

.blog-carousel-indicators-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 50px;
  right: 0px;
  left: 0px;
  margin: auto;
  width: 10%;
}

.blog-carousel-indicator {
  cursor: pointer;
}
