.statement-container {
  display: flex;
  align-items: stretch;
  flex: 1;
  background: #f8f8f6;
}

.statement-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
}

.statement-content-container div {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
  margin: 0px;
}

.statement-content-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 64px;
  color: #000000;
  margin: 25px 0px;
}

.statement-content-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px 0px 25px;
  width: 450px;
}
.statement-video-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;
}

.statement-video-container iframe {
  width: 100%;
}

.btn-container {
  width: 60%;
}

.cotton-vector-container1 {
  position: absolute;
  top: 0px;
  left: 0px;
}

.cotton-vector-container2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
