.product-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 50px;
}

.product-ratings-container {
  display: flex;
  align-items: center;
}

.product-ratings-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-left: 10px;
  margin-top: -5px;
}

.product-title-container {
  display: flex;
  align-items: center;
}

.product-title-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-price-container {
  display: flex;
  align-items: center;
}

.product-price-container span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292826;
}

.product-price-container span:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #797171;
  margin-left: 5px;
}

.product-description {
  margin-top: 30px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}

.product-quantity-boxes {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.product-quantity-boxes div {
  margin-right: 20px;
}

.product-quantity-input {
  display: flex;
  align-items: center;
}

.product-quantity-input label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #292826;
  margin-right: 10px;
}

.product-quantity-input input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #292826;
}

.add-to-bag-btn {
  margin-top: 50px;
}

.product-benefits-container {
  margin-top: 40px;
}

.product-benefits-container div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.product-benefit {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin-left: 10px;
  margin-top: -5px;
}
