.commitment-container {
  background: #f8efdd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
}

.commitment-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.commitments-list-container {
  display: flex;
  align-items: flex-start;
  padding: 50px 0px 0px;
  justify-content: space-around;
}

.commitment-item {
  display: flex;
  flex: 1;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}

.commitment-item:nth-last-child(1) {
  margin-right: 50px;
}

.commitment-item img {
  width: 80px;
  height: auto;
}

.commitment-item h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292826;
  margin: 10px 0px;
}

.commitment-item p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px;
}
