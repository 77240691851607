.goal-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  background-color: #e3eaf8;
}

.goal-container h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.goals {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.goal {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0px 50px;
}

.goal img {
  width: 80px;
  height: auto;
}

.goal p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}
