.pf-form {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
}

.pf-form div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pf-form div:nth-child(5) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pf-form div:nth-child(6) {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-top: 40px;
}

.pf-form div:nth-child(7) {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.pf-form input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.pf-form div:nth-child(5) label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
  text-transform: none;
  letter-spacing: 0em;
  position: relative;
  cursor: pointer;
}

.pf-form div:nth-child(5) label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: white;
  cursor: pointer;
}

.pf-form input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #292826;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #292826, 4px 0 0 #292826, 4px -2px 0 #292826,
    4px -4px 0 #292826, 4px -6px 0 #292826, 4px -8px 0 #292826;
  transform: rotate(45deg);
}

.bottom-prompt span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4em;
  color: #292826;
  margin-right: 10px;
  text-transform: uppercase;
}

.bottom-prompt span:nth-child(2) {
  cursor: pointer;
  border-bottom: 1px solid #292826;
  padding-bottom: 5px;
}

/* for Sign in  */

.sign-in-button-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
  width: 100%;
}

.sign-in-button-row div:nth-child(2) {
  display: flex;
  align-items: flex-start;
}

.sign-in-button-row div:nth-child(2) span {
  cursor: pointer;
  border-bottom: 1px solid #292826;
  padding-bottom: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
  margin-left: 20px;
  word-wrap: none;
  text-transform: uppercase;
}

/* reset-password */

.reset-password-heading-desc {
  margin-bottom: 30px;
}

.pf-form h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
}
