.plastFree-stats-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f1e9f3;
  padding: 80px;
  overflow-x: hidden;
}

.plastFree-stats-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.plastFree-stats {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.plastFree-stats div {
  position: relative;
  background-color: #fff;
  height: 150px;
  width: 150px;
  border-radius: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
}

.plastFree-stats span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #000000;
}

.plastFree-stats span:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
}
.plastFree-stats-tooltip {
  visibility: hidden;
  position: absolute;
  background: #dcadab;
  border-radius: 3px;
  top: -50%;
  right: 50%;
  width: 200%;
  padding: 15px;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s;
}

.plastFree-stats-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 94%;
  margin-left: -20px;
  margin-top: -20px;
  border-width: 20px;
  border-style: solid;
  transform: rotate(90deg);
  border-color: #dcadab transparent transparent transparent;
}

.plastFree-stats div:hover .plastFree-stats-tooltip {
  visibility: visible;
  opacity: 1;
}

.plastFree-stats-tooltip-al {
  visibility: hidden;
  position: absolute;
  background: #dcadab;
  border-radius: 3px;
  top: -50%;
  right: 50%;
  width: 200%;
  padding: 15px;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s;
}

.plastFree-stats-tooltip-al::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  right: 94%;
  margin-left: -22px;
  margin-top: -22px;
  border-width: 20px;
  border-style: solid;
  transform: rotate(90deg);
  border-color: #dcadab transparent transparent transparent;
}

.plastFree-stats div:hover .plastFree-stats-tooltip-al {
  visibility: visible;
  opacity: 1;
}
