.one-off-purchase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f4f4;
  padding: 50px;
}

.one-off-purchase-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
  margin: 0px;
}

.one-off-purchase-products-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 50px;
}

.one-off-purchase-products-category-button {
  margin-top: 20px;
  margin-right: 50px;
}

.one-off-purchase-main {
  display: flex;
  justify-content: space-evenly;
  margin-top: 70px;
}

.one-off-purchase-images-container {
  flex: 1;
}

.one-off-purchase-details-container {
  flex: 1;
}
