.cart-pane-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding: 40px 0px;
}

.cart-pane-item {
  display: flex;
}

.cart-pane-item-image {
  border: 1px solid white;
  display: flex;
}
.cart-pane-item-image img {
  flex: 1;
}

.cart-pane-item-details {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.cart-pane-item-details h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #292826;
  margin: 0px 0px 5px;
}

.cart-pane-item-details div {
  display: flex;
  flex-direction: column;
}

.cart-pane-item-details span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #78736a;
}

.cart-pane-item-remove {
  align-self: flex-start;
}

.cart-pane-item-remove div {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
  padding-bottom: 5px;
  border-bottom: 1px solid #292826;
  cursor: pointer;
}
