.certifications-banner-container {
  display: flex;
  align-items: center;
}

.certifications-banner-container img {
  width: 100%;
  position: relative;
}

.certifications-banner-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  width: 50%;
  margin-left: 50px;
}

.certifications-banner-content h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  text-transform: uppercase;
}

.certifications-banner-content p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: white;
  margin: 20px 0px 30px;
}
