.my-profile-edit-container {
  display: flex;
  flex-direction: column;
  flex: 5;
  padding: 50px;
}

.my-profile-edit-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.my-profile-edit-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.profile-form-input-holder {
  display: flex;
  flex-direction: column;
  flex: 40%;
  margin-top: 1%;
  margin-right: 5%;
}

.profile-edit-save-holder {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.profile-edit-save-holder span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
  margin-left: 20px;
  border-bottom: #292826 1px solid;
  padding-bottom: 5px;
  text-transform: uppercase;
}
