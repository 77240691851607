.why-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.why-img-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-img-container img {
  width: 100%;
  height: auto;
}

.why-content-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-content-container div {
  padding: 50px;
}

.why-content-container h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
}

.why-content-container h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin: 15px 0px;
}

.why-content-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}
