.ingredients-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #f8efdd;
  padding: 50px;
}

.ingredients-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.ingredients-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
}

.ingredients-icons div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.ingredients-icons img {
  width: 80px;
  height: 80px;
}

.ingredients-icons span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #292826;
  text-transform: uppercase;
  margin-top: 25px;
}
