.foundation-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  background-color: #e3d4cb;
}

.foundation-container h3 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #292826;
}

div.foundation-container span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

.foundation-desc-container {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
}

.foundation-desc-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px 20px;
  flex: 1;
}

div.foundation-container span:nth-child(4) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #292826;
}

.foundation-icons-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.foundation-icon {
  margin: 25px 50px;
  width: 10%;
}
