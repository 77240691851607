.monthly-box-container {
  position: relative;
  margin: 0px;
}

.monthly-box-bg {
  width: 100%;
  height: auto;
  display: block;
}

.monthly-box-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 125px;
  left: 125px;
}

.monthly-box-content h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
  margin: 0px;
  padding: 0px;
}

.monthly-box-content h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  margin: 20px 0px;
  width: 500px;
}

.monthly-box-content p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px;
  padding: 0px;
}

.shop-btn-container {
  width: 125px;
  margin-top: 30px;
}
