.ambassador-container {
  background: #e3eaf8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ambassador-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 50px;
}

.ambassador-image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ambassador-image-container img {
  flex: 1;
  width: 100%;
  height: auto;
}

.ambassador-container h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
  margin: 0px;
}

.ambassador-container p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
  color: #292826;
  margin: 20px 0px;
}

.ambassador-container h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #292826;
  margin: 0px;
}

.ambassador-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #292826;
  margin: -20px 0px 0px;
}

.ambassador-btn-container {
  margin-top: 20px;
}
