.contact-us-footer {
  display: flex;
  background-color: #ebe4db;
  padding: 50px;
}

.contact-us-footer-address-holder {
  flex: 1;
}

.contact-us-footer-address-holder h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  color: #292826;
  margin: 0px 0px 30px;
}

.contact-us-footer-address-holder h4 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
  margin: 0px 0px 10px;
}

.address-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.contact-us-footer-address-holder span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #292826;
}

.contact-us-contact-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.contact-us-working-hours {
  display: flex;
  flex-direction: column;
}

.contact-us-footer-form {
  flex: 1;
}

.send-message-holder {
  width: 50%;
  margin-top: 30px;
}
