@import "../../../Styles/Typography.css";

.banner-container {
  position: relative;
}

.carousel-container {
  position: relative;
}

.carousel-item {
  position: relative;
}

.carousel-content {
  position: absolute;
  left: 60px;
  top: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  bottom: 0px;
}

.carousel-content h1 {
  font-family: "Lustria", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 75px;
  line-height: 75px;
  letter-spacing: -0.03em;
  color: #ffffff;
  width: 522px;
  text-align: start;
}

.carousel-indicators-container {
  position: absolute;
  right: 30px;
  top: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  bottom: 0px;
}

.carousel-indicator {
  cursor: pointer;
}

.home-header-wrap {
  position: absolute;
  top: 0px;
  width: 100%;
}
