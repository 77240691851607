.subscription-product-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 0px;
  width: 275px;
}

.sub-icon-container {
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  margin-bottom: -5px;
  margin-right: 10px;
}

.sub-title-container {
  flex: 1;
}
.sub-product-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
}

.sub-product-add-button {
  background: #f0f2f4;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  padding: 20px 13px;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}

.sub-product-add-button:hover {
  opacity: 0.6;
}

.sub-product-add-button:active {
  opacity: 1;
}

.sub-product-add-button span {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #292826;
  text-align: center;
  user-select: none;
}
