.press-main-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #ebe4db;
  padding: 50px;
}

.press-content {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 3%;
  margin-bottom: 3%;
}

.press-image-holder {
  height: 226px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.press-timestamp {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #292826;
  margin-top: 20px;
  text-transform: uppercase;
}

.press-title {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #292826;
  margin-top: 15px;
  margin-bottom: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: 28px; /* fallback */
  max-height: 28px * 2; /* fallback */
}

.press-read-more {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid #292826;
  align-self: flex-start;
}

@media only screen and (min-width: 1700px) {
  .press-image-holder {
    height: 326px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
