.custom-box-container {
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.custom-box-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #292826;
  align-self: center;
}

.custom-box-product-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.custom-cart-total-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.custom-cart-total-container span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292826;
}

.custom-cart-total-container span:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #292826;
  margin-left: 10px;
}
