@import "../../../Styles/Typography.css";

.top-header {
  background: #602714;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-header p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
}

.top-header span {
  font-weight: 600;
}

.top-header .close {
  color: #fff;
  float: right;
  font-size: 18px;
  line-height: 21px;
}
