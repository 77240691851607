.my-profile-container {
  display: flex;
  flex-direction: column;
  flex: 5;
  padding: 50px;
}

.my-profile-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-profile-title-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.my-profile-title-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.2em;
  color: #292826;
  border-bottom: #292826 solid 1px;
  padding-bottom: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.my-profile-details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.my-profile-details div {
  display: flex;
  flex-direction: column;
  flex: 50%;
}

.my-profile-subtitle {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #78736a;
  text-transform: uppercase;
}

.my-profile-sub-details {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
  margin-block: 20px;
  display: flex;
  align-items: center;
}

.address-title {
  margin-right: 10px;
  margin-top: -3px;
}
