.about-story-container {
  display: flex;
  justify-content: space-between;
  border-color: #f8f8f6;
}

.about-story-left-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 50px;
}

.about-story-left-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
}

.about-story-left-container h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 64px;
  color: #000000;
  margin: 25px 0px;
}

.about-story-left-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}

.about-story-right-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 50px;
}

.about-story-right-container img {
  width: 100%;
}
