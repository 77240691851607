html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.auth-layout-container {
  display: flex;
  background: #ebe4db;
  position: relative;
}

.auth-layout-title-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.auth-layout-title-container-no-cover {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 50px;
}

.auth-layout-title-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
}

.auth-layout-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 50px;
  position: absolute;
}

.auth-layout-title-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 50px;
  color: #292826;
  text-align: left;
}

.auth-layout-title-container-no-cover h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 50px;
  color: #292826;
  text-align: left;
}

.auth-layout-separator {
  height: 83%;
  width: 2px;
  background-color: white;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: auto;
  position: absolute;
}

.auth-layout-form-container {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 50px 50px 50px;
}
