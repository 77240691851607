.pad-difference-container {
  display: flex;
}

.conventional-pads-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #d6ecdb;
  padding: 50px;
}

.conventional-pads-container div {
  display: flex;
}

.conventional-pads-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin-left: 10px;
}

.conventional-pads-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin-left: 10px;
  margin-bottom: 20px;
}

.plastfree-pads-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #f8efdd;
  padding: 50px;
}

.plastfree-pads-container div {
  display: flex;
}

.plastfree-pads-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin-left: 10px;
}

.plastfree-pads-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin-left: 10px;
  margin-bottom: 20px;
}
