@import url(https://fonts.googleapis.com/css2?family=Lustria&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
html {
  scroll-behavior: smooth;
  scroll-padding: 100px;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
}


.foundation-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  background-color: #e3d4cb;
}

.foundation-container h3 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #292826;
}

div.foundation-container span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

.foundation-desc-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 30px 0px;
}

.foundation-desc-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

div.foundation-container span:nth-child(4) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #292826;
}

.foundation-icons-container {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.foundation-icon {
  margin: 25px 50px;
  width: 10%;
}

.goal-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  background-color: #e3eaf8;
}

.goal-container h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.goals {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 50px;
}

.goal {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0px 50px;
}

.goal img {
  width: 80px;
  height: auto;
}

.goal p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}

.mission-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  background-color: #f8efdd;
}

.mission-container h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.missions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 50px;
}

.mission {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0px 50px;
}

.mission img {
  width: 80px;
  height: auto;
}

.mission h5 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292826;
  margin: 20px 0px 10px;
}

.mission p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px;
}

.about-story-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-color: #f8f8f6;
}

.about-story-left-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
}

.about-story-left-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
}

.about-story-left-container h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 64px;
  color: #000000;
  margin: 25px 0px;
}

.about-story-left-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}

.about-story-right-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
}

.about-story-right-container img {
  width: 100%;
}


.form-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.form-input label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
}

.form-input input {
  outline: none;
  border-width: 0px 0px 1px 0px;
  border-bottom-color: #797171;
  border-bottom-style: groove;
  background-color: #ebe4db;
  margin: 20px 0px 20px;
  padding-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
}

.form-input input::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #78736a;
}

.form-input input:-ms-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #78736a;
}

.form-input input::placeholder {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #78736a;
}

.form-input input:focus::-webkit-input-placeholder {
  color: transparent;
}
.form-input input:focus:-moz-placeholder {
  color: transparent;
}
.form-input input:focus::-moz-placeholder {
  color: transparent;
}
.form-input input:focus:-ms-input-placeholder {
  color: transparent;
}

.pfbutton-container {
  background: #ffffff;
  border: 2px solid #602714;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4em;
  color: #292826;
  padding: 18px 42px;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

.pfbutton-container-border-less {
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4em;
  color: #292826;
  padding: 18px 42px;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

.pf-form {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
}

.pf-form div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.pf-form div:nth-child(5) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.pf-form div:nth-child(6) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 30%;
  margin-top: 40px;
}

.pf-form div:nth-child(7) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 50px;
}

.pf-form input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.pf-form div:nth-child(5) label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
  text-transform: none;
  letter-spacing: 0em;
  position: relative;
  cursor: pointer;
}

.pf-form div:nth-child(5) label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: white;
  cursor: pointer;
}

.pf-form input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #292826;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #292826, 4px 0 0 #292826, 4px -2px 0 #292826,
    4px -4px 0 #292826, 4px -6px 0 #292826, 4px -8px 0 #292826;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.bottom-prompt span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4em;
  color: #292826;
  margin-right: 10px;
  text-transform: uppercase;
}

.bottom-prompt span:nth-child(2) {
  cursor: pointer;
  border-bottom: 1px solid #292826;
  padding-bottom: 5px;
}

/* for Sign in  */

.sign-in-button-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
  width: 100%;
}

.sign-in-button-row div:nth-child(2) {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.sign-in-button-row div:nth-child(2) span {
  cursor: pointer;
  border-bottom: 1px solid #292826;
  padding-bottom: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
  margin-left: 20px;
  word-wrap: none;
  text-transform: uppercase;
}

/* reset-password */

.reset-password-heading-desc {
  margin-bottom: 30px;
}

.pf-form h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.auth-layout-container {
  display: -webkit-flex;
  display: flex;
  background: #ebe4db;
  position: relative;
}

.auth-layout-title-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.auth-layout-title-container-no-cover {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 50px;
}

.auth-layout-title-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
}

.auth-layout-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px 50px;
  position: absolute;
}

.auth-layout-title-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 50px;
  color: #292826;
  text-align: left;
}

.auth-layout-title-container-no-cover h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 50px;
  color: #292826;
  text-align: left;
}

.auth-layout-separator {
  height: 83%;
  width: 2px;
  background-color: white;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: auto;
  position: absolute;
}

.auth-layout-form-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 50px 50px 50px 50px;
}

.certifications-banner-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.certifications-banner-container img {
  width: 100%;
  position: relative;
}

.certifications-banner-content {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 50%;
  margin-left: 50px;
}

.certifications-banner-content h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  text-transform: uppercase;
}

.certifications-banner-content p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: white;
  margin: 20px 0px 30px;
}

.certifications-main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-block: 50px;
}

.certifications-main-container p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #292826;
  width: 75%;
  margin-bottom: 50px;
}

.certificate-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: #ffffff;
  border: 1px solid #e4e3e1;
  box-sizing: border-box;
  width: 85%;
  margin-bottom: 30px;
}

.certificate-container img {
  width: 200px;
  height: auto;
}

.certificate-container span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #292826;
}

.certificate-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  text-align: left;
}

.pad-difference-container {
  display: -webkit-flex;
  display: flex;
}

.conventional-pads-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #d6ecdb;
  padding: 50px;
}

.conventional-pads-container div {
  display: -webkit-flex;
  display: flex;
}

.conventional-pads-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin-left: 10px;
}

.conventional-pads-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin-left: 10px;
  margin-bottom: 20px;
}

.plastfree-pads-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #f8efdd;
  padding: 50px;
}

.plastfree-pads-container div {
  display: -webkit-flex;
  display: flex;
}

.plastfree-pads-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin-left: 10px;
}

.plastfree-pads-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin-left: 10px;
  margin-bottom: 20px;
}

.contact-us-banner-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.contact-us-banner-container img {
  width: 100%;
  position: relative;
}

.contact-us-banner-content {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 50%;
  margin-left: 50px;
}

.contact-us-banner-content h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: black;
  text-transform: uppercase;
}

.contact-us-banner-content p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: black;
  margin: 20px 0px 30px;
}

.contact-text-area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.contact-text-area label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
}

.contact-text-area textarea {
  background: #f8f8f6;
  border: 1px solid #797171;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
  resize: vertical;
  min-height: 100px;
}

.contact-us-footer {
  display: -webkit-flex;
  display: flex;
  background-color: #ebe4db;
  padding: 50px;
}

.contact-us-footer-address-holder {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.contact-us-footer-address-holder h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  color: #292826;
  margin: 0px 0px 30px;
}

.contact-us-footer-address-holder h4 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
  margin: 0px 0px 10px;
}

.address-holder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px;
}

.contact-us-footer-address-holder span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #292826;
}

.contact-us-contact-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px;
}

.contact-us-working-hours {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contact-us-footer-form {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.send-message-holder {
  width: 50%;
  margin-top: 30px;
}

.contact-us-main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.contact-us-main-content {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.contact-us-main-content-child {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  aspect-ratio: 1.5;
}

.contact-us-main-content-child h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #292826;
  margin: 0px 0px 10px;
}

.contact-us-main-content-child span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #78736a;
}

.contact-us-separator {
  width: 1px;
  background-color: #e4e3e1;
  margin-block: 10%;
}

.ambassador-container {
  background: #e3eaf8;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.ambassador-content {
  -webkit-flex: 2 1;
          flex: 2 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 0px 50px;
}

.ambassador-image-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.ambassador-image-container img {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: auto;
}

.ambassador-container h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
  margin: 0px;
}

.ambassador-container p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
  color: #292826;
  margin: 20px 0px;
}

.ambassador-container h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #292826;
  margin: 0px;
}

.ambassador-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #292826;
  margin: -20px 0px 0px;
}

.ambassador-btn-container {
  margin-top: 20px;
}



.header-container {
  background-color: white;
  padding: 0px 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 2000;
}

.header-container-transparent {
  background-color: transparent;
  padding: 0px 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.logo-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 20px 0px;
}

.nav-menu-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 40px 0px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu-container li {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #292826;
  padding-bottom: 6px;
  cursor: pointer;
}

.nav-menu-container-home {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 40px 0px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu-container-home li {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: white;
  padding-bottom: 6px;
  cursor: pointer;
}

.underlined-nav {
  border-bottom: 2px #292826 solid;
}

.account-bag-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px 0px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.account-logo-wrap {
  margin-right: 30px;
}

.cart-bag-count {
  font-family: "Open Sans", sans-serif;
  width: 16px;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  margin-left: 10px;
  margin-bottom: 5px;
  line-height: 18px;
  text-align: center;
  color: #292826;
  cursor: pointer;
}

.cart-bag-count-white {
  font-family: "Open Sans", sans-serif;
  width: 16px;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  margin-left: 10px;
  margin-bottom: 5px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.banner-container {
  position: relative;
}

.carousel-container {
  position: relative;
}

.carousel-item {
  position: relative;
}

.carousel-content {
  position: absolute;
  left: 60px;
  top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  bottom: 0px;
}

.carousel-content h1 {
  font-family: "Lustria", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 75px;
  line-height: 75px;
  letter-spacing: -0.03em;
  color: #ffffff;
  width: 522px;
  text-align: start;
}

.carousel-indicators-container {
  position: absolute;
  right: 30px;
  top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  bottom: 0px;
}

.carousel-indicator {
  cursor: pointer;
}

.home-header-wrap {
  position: absolute;
  top: 0px;
  width: 100%;
}

.monthly-box-container {
  position: relative;
  margin: 0px;
}

.monthly-box-bg {
  width: 100%;
  height: auto;
  display: block;
}

.monthly-box-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 125px;
  left: 125px;
}

.monthly-box-content h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
  margin: 0px;
  padding: 0px;
}

.monthly-box-content h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  margin: 20px 0px;
  width: 500px;
}

.monthly-box-content p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px;
  padding: 0px;
}

.shop-btn-container {
  width: 125px;
  margin-top: 30px;
}

.news-logos-container {
  background: #f8f8f6;
  padding: 40px 0px;
  border: 1px solid #f2f2e6;
}

.logo-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.logo-item img {
  width: 50%;
}

.arrow-left {
  position: absolute;
  left: 0px;
}

.arrow-right {
  position: absolute;
  right: 0px;
}

.react-multi-carousel-list {
  padding: 10px;
}

.blog-carousel-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.blog-carousel-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.blog-carousel-cover-image-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.blog-carousel-content-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.blog-carousel-content {
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #f1e9f3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 50px;
}

.blog-carousel-content h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
  text-align: left;
  margin: 0px;
}

.blog-carousel-content h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  text-align: left;
  margin: 20px 0px;
}

.blog-carousel-content p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  text-align: left;
  margin: 0px;
}

.learn-more-btn-container {
  margin-top: 30px;
}

.blog-carousel-indicators-container {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  bottom: 50px;
  right: 0px;
  left: 0px;
  margin: auto;
  width: 10%;
}

.blog-carousel-indicator {
  cursor: pointer;
}

.commitment-container {
  background: #f8efdd;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 100px 0px;
}

.commitment-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.commitments-list-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 50px 0px 0px;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.commitment-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-left: 50px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.commitment-item:nth-last-child(1) {
  margin-right: 50px;
}

.commitment-item img {
  width: 80px;
  height: auto;
}

.commitment-item h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292826;
  margin: 10px 0px;
}

.commitment-item p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px;
}

.partners-container {
  background-color: #ffffff;
  padding: 50px 0px;
}

.partners-container h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #292826;
}

.partner-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.partner-content img {
  width: 100px;
}

.statement-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #f8f8f6;
}

.statement-content-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  z-index: 0;
}

.statement-content-container div {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
  margin: 0px;
}

.statement-content-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 64px;
  color: #000000;
  margin: 25px 0px;
}

.statement-content-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin: 0px 0px 25px;
  width: 450px;
}
.statement-video-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 1000;
}

.statement-video-container iframe {
  width: 100%;
}

.btn-container {
  width: 60%;
}

.cotton-vector-container1 {
  position: absolute;
  top: 0px;
  left: 0px;
}

.cotton-vector-container2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.testimonials-container {
  background: #f8efdd;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 100px 40px;
}

.testimonials-container h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #292826;
}

.testimonials-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
}

.testimonials-ratings {
  display: -webkit-flex;
  display: flex;
}

.testimonials-ratings-count {
  margin-left: 10px;
}

.reviews-container {
  width: 100%;
}

.review-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.review-desc {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  text-align: center;
  color: #292826;
  width: 65%;
}

.review-author {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #292826;
}

.react-multi-carousel-list {
  padding: 30px;
}

.star {
  margin-right: 2px;
}

.mini-cart-pane {
  background-color: #ebe4db;
  position: relative;
}

.slide-pane__overlay {
  z-index: 3000;
}

.slide-pane__content {
  padding: 0px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slide-pane__content::-webkit-scrollbar {
  display: none;
}

.cart-pane-main {
  padding: 40px;
}

.cart-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
}

.cart-title span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #292826;
}

.cart-title span:nth-child(2) {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #292826;
  margin-left: 10px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.cart-pane-button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  position: -webkit-sticky;
  position: sticky;
  -webkit-flex: 1 1;
          flex: 1 1;
  bottom: 0%;
  width: 100%;
  padding: 40px 0px;
  border-top: 1px solid white;
  background-color: #ebe4db;
}

.cart-pane-item-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid white;
  padding: 40px 0px;
}

.cart-pane-item {
  display: -webkit-flex;
  display: flex;
}

.cart-pane-item-image {
  border: 1px solid white;
  display: -webkit-flex;
  display: flex;
}
.cart-pane-item-image img {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.cart-pane-item-details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
}

.cart-pane-item-details h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #292826;
  margin: 0px 0px 5px;
}

.cart-pane-item-details div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.cart-pane-item-details span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #78736a;
}

.cart-pane-item-remove {
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.cart-pane-item-remove div {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
  padding-bottom: 5px;
  border-bottom: 1px solid #292826;
  cursor: pointer;
}

.top-header {
  background: #602714;
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.top-header p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
}

.top-header span {
  font-weight: 600;
}

.top-header .close {
  color: #fff;
  float: right;
  font-size: 18px;
  line-height: 21px;
}

.product-banner-container img {
  display: block;
}

.products-commitment-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.products-commitment-content {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.products-commitment-content div {
  padding: 50px;
}

.products-commitment-content h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
}

.products-commitment-content h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

.products-commitment-content p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}

.products-commitment-img-container {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.products-commitment-img-container img {
  width: 100%;
  height: auto;
}

.ingredients-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  background: #f8efdd;
  padding: 50px;
}

.ingredients-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.ingredients-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 50px;
}

.ingredients-icons div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.ingredients-icons img {
  width: 80px;
  height: 80px;
}

.ingredients-icons span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #292826;
  text-transform: uppercase;
  margin-top: 25px;
}

.one-off-purchase-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background: #f4f4f4;
  padding: 50px;
}

.one-off-purchase-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
  margin: 0px;
}

.one-off-purchase-products-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  margin-top: 50px;
}

.one-off-purchase-products-category-button {
  margin-top: 20px;
  margin-right: 50px;
}

.one-off-purchase-main {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-top: 70px;
}

.one-off-purchase-images-container {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.one-off-purchase-details-container {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.quantity-box-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

.quantity-box {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 18px 22px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.5s ease-in;
}

.quantity-box-inactive {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 5px;
  padding: 18px 22px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.p-quantity-box {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #f9c2ab;
  border: 1px solid #f9c2ab;
  border-radius: 5px;
  padding: 18px 22px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.5s ease-in;
}

.p-quantity-box-inactive {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 5px;
  padding: 18px 22px;
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.p-quantity-box-inactive:hover {
  background: #f9c2ab1f;
}

.quantity-box-inactive:hover {
  background-color: #ffffff1f;
}

.quantity {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #292826;
}

.months-text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #292826;
  margin-top: 5px;
}

.offer-star {
  position: absolute;
  top: -20%;
  right: -15%;
}
.offer-value {
  position: absolute;
  top: 20%;
  right: 15%;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
}

.product-details-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-left: 50px;
}

.product-ratings-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.product-ratings-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-left: 10px;
  margin-top: -5px;
}

.product-title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.product-title-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-price-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.product-price-container span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292826;
}

.product-price-container span:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #797171;
  margin-left: 5px;
}

.product-description {
  margin-top: 30px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}

.product-quantity-boxes {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20px;
}

.product-quantity-boxes div {
  margin-right: 20px;
}

.product-quantity-input {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.product-quantity-input label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #292826;
  margin-right: 10px;
}

.product-quantity-input input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #292826;
}

.add-to-bag-btn {
  margin-top: 50px;
}

.product-benefits-container {
  margin-top: 40px;
}

.product-benefits-container div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 5px;
}

.product-benefit {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
  margin-left: 10px;
  margin-top: -5px;
}

.product-image-carousel-thumbs {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 20px;
}

.thumb-inactive {
  width: 75px;
  height: 75px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  margin: 0px 20px 20px 0px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.thumb-inactive:hover {
  opacity: 0.8;
}

.thumb-active {
  width: 75px;
  height: 75px;
  border: 2px solid #602714;
  box-sizing: border-box;
  margin: 0px 20px 20px 0px;
}

.plastFree-stats-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #f1e9f3;
  padding: 80px;
  overflow-x: hidden;
}

.plastFree-stats-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.plastFree-stats {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.plastFree-stats div {
  position: relative;
  background-color: #fff;
  height: 150px;
  width: 150px;
  border-radius: 75px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 20px;
}

.plastFree-stats span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #000000;
}

.plastFree-stats span:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
}
.plastFree-stats-tooltip {
  visibility: hidden;
  position: absolute;
  background: #dcadab;
  border-radius: 3px;
  top: -50%;
  right: 50%;
  width: 200%;
  padding: 15px;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s;
}

.plastFree-stats-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 94%;
  margin-left: -20px;
  margin-top: -20px;
  border-width: 20px;
  border-style: solid;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  border-color: #dcadab transparent transparent transparent;
}

.plastFree-stats div:hover .plastFree-stats-tooltip {
  visibility: visible;
  opacity: 1;
}

.plastFree-stats-tooltip-al {
  visibility: hidden;
  position: absolute;
  background: #dcadab;
  border-radius: 3px;
  top: -50%;
  right: 50%;
  width: 200%;
  padding: 15px;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s;
}

.plastFree-stats-tooltip-al::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  right: 94%;
  margin-left: -22px;
  margin-top: -22px;
  border-width: 20px;
  border-style: solid;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  border-color: #dcadab transparent transparent transparent;
}

.plastFree-stats div:hover .plastFree-stats-tooltip-al {
  visibility: visible;
  opacity: 1;
}

.custom-box-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 10px;
}

.custom-box-item-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #292826;
}

.custom-box-item-count {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #292826;
}

.custom-box-item-count-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.custom-box-item-count-container span {
  margin-right: 15px;
}

.custom-box-item-count-container span:nth-child(2) {
  margin-right: 5px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.custom-box-item-count-container span:nth-child(2):hover {
  opacity: 0.6;
}

.custom-box-item-count-container span:nth-child(3) {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.custom-box-item-count-container span:nth-child(3):hover {
  opacity: 0.6;
}

.custom-box-container {
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.custom-box-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #292826;
  -webkit-align-self: center;
          align-self: center;
}

.custom-box-product-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.custom-cart-total-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-top: 30px;
}

.custom-cart-total-container span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292826;
}

.custom-cart-total-container span:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #292826;
  margin-left: 10px;
}

.product-box-container {
  height: 171px;
  width: 171px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  background: #f9c2ab;
  padding: 0px 40px;
  -webkit-flex-basis: calc(50% - 10px);
          flex-basis: calc(50% - 10px);
  margin-bottom: 10px;
}

.product-box-container h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #292826;
  margin: 0px;
}

.product-box-type {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #292826;
}

.product-box-count {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #292826;
  margin-top: 20px;
}

.subscription-box-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background: #e3d4cb;
  padding: 50px;
}

.subscription-box-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.subscription-box-description-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.subscription-box-description-container div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.sub-desc {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: #292826;
  margin-top: -5px;
  margin-left: 10px;
}

.sub-desc-2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
  margin-top: -5px;
  margin-left: 5px;
}

.sub-desc-plus {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
  margin: -5px 20px 0px;
}

.subscription-box-main {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  margin-top: 50px;
}

.subscription-products-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.subscription-boxes-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.subscription-cart-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.quantity-box-container {
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.subscribe-button {
  margin: 30px 0px;
}

.subscription-product-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 0px;
  width: 275px;
}

.sub-icon-container {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 20px;
  margin-bottom: -5px;
  margin-right: 10px;
}

.sub-title-container {
  -webkit-flex: 1 1;
          flex: 1 1;
}
.sub-product-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
}

.sub-product-add-button {
  background: #f0f2f4;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px 13px;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}

.sub-product-add-button:hover {
  opacity: 0.6;
}

.sub-product-add-button:active {
  opacity: 1;
}

.sub-product-add-button span {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #292826;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.trial-pack-container {
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.trial-pack-content-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.button-container {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin: 25px 0px 0px 0px;
}

.trial-pack-content {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
}

.trial-pack-content h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 61px;
  color: #000000;
  margin: 0px;
}

.trial-pack-content span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: #000000;
  margin-left: 15px;
  word-break: break-all;
}

.trial-pack-image-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.trial-pack-image-container img {
  height: auto;
  width: 100%;
}

.why-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.why-img-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.why-img-container img {
  width: 100%;
  height: auto;
}

.why-content-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.why-content-container div {
  padding: 50px;
}

.why-content-container h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292826;
}

.why-content-container h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin: 15px 0px;
}

.why-content-container p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #292826;
}


.left-pane-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 50px;
}

.left-pane-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.left-pane-container div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-block: 15px;
  cursor: pointer;
}

.pane-section-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #78736a;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
}

.active-pane {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
  color: #292826;
}

.left-pane-container div:hover .pane-section-title {
  color: #292826c0;
}

.my-profile-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 5 1;
          flex: 5 1;
  padding: 50px;
}

.my-profile-title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.my-profile-title-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.my-profile-title-container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.2em;
  color: #292826;
  border-bottom: #292826 solid 1px;
  padding-bottom: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.my-profile-details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.my-profile-details div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 50% 1;
          flex: 50% 1;
}

.my-profile-subtitle {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #78736a;
  text-transform: uppercase;
}

.my-profile-sub-details {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
  margin-block: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.address-title {
  margin-right: 10px;
  margin-top: -3px;
}

.my-profile-edit-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 5 1;
          flex: 5 1;
  padding: 50px;
}

.my-profile-edit-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.my-profile-edit-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.profile-form-input-holder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 40% 1;
          flex: 40% 1;
  margin-top: 1%;
  margin-right: 5%;
}

.profile-edit-save-holder {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
}

.profile-edit-save-holder span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
  margin-left: 20px;
  border-bottom: #292826 1px solid;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.order-history-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 5 1;
          flex: 5 1;
  padding: 50px;
}

.order-history-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.order-history-table {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.order-history-table tr {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #ffffff;
  padding-block: 20px;
}

.order-history-table th {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  text-align: start;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.order-history-table th:nth-last-child(1) {
  text-align: end;
}

.order-history-table td {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #292826;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.order-history-table td:nth-last-child(1) {
  text-align: end;
}

.order-history-table td:nth-last-child(1) span {
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid #292826;
  cursor: pointer;
}

.order-details-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 5 1;
          flex: 5 1;
  padding: 50px;
}

.order-details-title-holder {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.order-details-title-holder h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.order-details-title-holder span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.2em;
  color: #292826;
  padding-bottom: 5px;
  border-bottom: 1px #292826 solid;
  text-transform: uppercase;
  cursor: pointer;
}

.order-info-holder {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: -20px;
}

.order-info-holder span:nth-child(2n-1) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #78736a;
  margin-right: 10px;
}

.order-info-holder span:nth-child(2n + 0) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #292826;
  margin-right: 30px;
  margin-top: -3px;
}

.order-details-table {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 30px;
}

.order-details-table tr {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #ffffff;
  padding-block: 20px;
}

.order-details-table th {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  text-align: end;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.order-details-table th:nth-child(1) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  text-align: start;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.order-details-table td {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #292826;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: end;
}

.order-details-table td:nth-child(1) {
  -webkit-flex: 2.5 1;
          flex: 2.5 1;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #292826;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: start;
}

.order-bill-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.order-bill-box-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 25%;
}

.order-bill-box-1 td {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.order-bill-box-1 td:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #292826;
}

.order-bill-box-1 tr {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-block: 20px;
}

.order-bill-box-2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 25%;
}

.order-bill-box-2 tr {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-block: 20px;
}

.order-bill-box-2 td {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.order-bill-box-2 td:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #292826;
}

.order-detail-separator {
  height: 0.5px;
  width: 100%;
  background-color: #ffffff;
}

.profile-container {
  display: -webkit-flex;
  display: flex;
  background-color: #ebe4db;
}

.faq-banner-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.faq-banner-container img {
  width: 100%;
  position: relative;
}

.faq-banner-content {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 50%;
  margin-left: 50px;
}

.faq-banner-content h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  text-transform: uppercase;
}

.faq-banner-content p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  margin: 20px 0px 5px;
}

.faq-contact-info {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 50px;
  color: #000000;
  margin-bottom: 20px;
}

.faq-main-container {
  display: -webkit-flex;
  display: flex;
  padding: 20px 20px 50px;
}

.faq-main-left-panel-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 50px;
}
.faq-main-left-panel-container div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 30px;
  cursor: pointer;
}

.faq-section-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #78736a;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
}

.faq-section-title-black {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
}

.faq-main-content-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 5 1;
          flex: 5 1;
  margin-inline: 50px;
}

.faq-main-content-container h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
  text-transform: capitalize;
}

.faq-ques-ans-holder {
  display: -webkit-flex;
  display: flex;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding-block: 20px;
}

.faq-ques-ans-holder div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 15px;
}

.faq-ques {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #292826;
  text-transform: uppercase;
  cursor: pointer;
}

.faq-ans {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #78736a;
  margin-top: 10px;
}


.press-banner-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.press-banner-container img {
  width: 100%;
  position: relative;
}

.press-banner-content {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 50%;
  margin-left: 50px;
}

.press-banner-content h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  text-transform: uppercase;
}

.press-banner-content p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  margin: 20px 0px 30px;
}

.press-main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background-color: #ebe4db;
  padding: 50px;
}

.press-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 30%;
  margin-right: 3%;
  margin-bottom: 3%;
}

.press-image-holder {
  height: 226px;
  width: 100%;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.press-timestamp {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #292826;
  margin-top: 20px;
  text-transform: uppercase;
}

.press-title {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #292826;
  margin-top: 15px;
  margin-bottom: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: 28px; /* fallback */
  max-height: 28px * 2; /* fallback */
}

.press-read-more {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid #292826;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

@media only screen and (min-width: 1700px) {
  .press-image-holder {
    height: 326px;
    width: 100%;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
}


.footer-container {
  background: #f8f8f6;
  padding: 50px;
}

.footer-top-content {
  display: -webkit-flex;
  display: flex;
}

.logo-and-contents {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.logo-and-contents p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #292826;
  width: 80%;
  margin: 15px 0px;
}

.social-icons-container {
  width: 160px;
}

.links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 2 1;
          flex: 2 1;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.links h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 0px;
}

.links ul {
  list-style: none;
  padding: 0px;
}

.links li {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #292826;
  cursor: pointer;
}

.subscription {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.social-icons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.subscription h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 0px;
}

.subscription p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #292826;
  margin: 20px 0px 20px;
}
.subscription span {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #602714;
}

.input-container {
  border-bottom: 1px solid #797171;
  display: -webkit-flex;
  display: flex;
  background-color: #f8f8f6;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.input-container input {
  border: none;
  background-color: #f8f8f6;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #292826;
  outline: none;
  width: 90%;
}

.footer-bottom-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 50px;
}

.footer-bottom-content div {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #292826;
}

.footer-bottom-content div span {
  cursor: pointer;
}
.blaze-water-mark {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #292826;
}

.static-modal-overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10%;
  z-index: 10000;
}

.static-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 5px;
  outline: none;
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 80%;
}

.static-modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-inline: 15px;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0px 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: white;
}

.static-modal-header h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #292826;
  text-transform: capitalize;
}

.static-modal-main {
  padding: 15px 25px;
  font-family: "Open Sans", sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.static-modal-main::-webkit-scrollbar {
  background-color: white;
  border-bottom-right-radius: 5px;
  width: 10px;
}

.static-modal-main::-webkit-scrollbar-track {
  border-left: 1px solid #ebebeb;
}

.static-modal-main::-webkit-scrollbar-thumb {
  background: #7c7c7c;
  border-radius: 30px;
  width: 10px;
}

.static-modal-main p {
  margin: 0px;
}

