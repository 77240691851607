.partners-container {
  background-color: #ffffff;
  padding: 50px 0px;
}

.partners-container h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #292826;
}

.partner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.partner-content img {
  width: 100px;
}
