.product-box-container {
  height: 171px;
  width: 171px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  background: #f9c2ab;
  padding: 0px 40px;
  flex-basis: calc(50% - 10px);
  margin-bottom: 10px;
}

.product-box-container h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #292826;
  margin: 0px;
}

.product-box-type {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #292826;
}

.product-box-count {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #292826;
  margin-top: 20px;
}
