.contact-us-main-container {
  display: flex;
  align-items: center;
}

.contact-us-main-content {
  flex: 1;
  display: flex;
}

.contact-us-main-content-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1.5;
}

.contact-us-main-content-child h2 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #292826;
  margin: 0px 0px 10px;
}

.contact-us-main-content-child span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #78736a;
}

.contact-us-separator {
  width: 1px;
  background-color: #e4e3e1;
  margin-block: 10%;
}
