.trial-pack-container {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trial-pack-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  width: 80%;
  display: flex;
  align-self: flex-start;
  margin: 25px 0px 0px 0px;
}

.trial-pack-content {
  display: inline-flex;
  align-items: center;
}

.trial-pack-content h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 61px;
  color: #000000;
  margin: 0px;
}

.trial-pack-content span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: #000000;
  margin-left: 15px;
  word-break: break-all;
}

.trial-pack-image-container {
  flex: 1;
  display: flex;
}

.trial-pack-image-container img {
  height: auto;
  width: 100%;
}
