.custom-box-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.custom-box-item-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #292826;
}

.custom-box-item-count {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #292826;
}

.custom-box-item-count-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.custom-box-item-count-container span {
  margin-right: 15px;
}

.custom-box-item-count-container span:nth-child(2) {
  margin-right: 5px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.custom-box-item-count-container span:nth-child(2):hover {
  opacity: 0.6;
}

.custom-box-item-count-container span:nth-child(3) {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.custom-box-item-count-container span:nth-child(3):hover {
  opacity: 0.6;
}
