.pfbutton-container {
  background: #ffffff;
  border: 2px solid #602714;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4em;
  color: #292826;
  padding: 18px 42px;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

.pfbutton-container-border-less {
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4em;
  color: #292826;
  padding: 18px 42px;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}
