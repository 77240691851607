.order-details-container {
  display: flex;
  flex-direction: column;
  flex: 5;
  padding: 50px;
}

.order-details-title-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-details-title-holder h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.order-details-title-holder span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.2em;
  color: #292826;
  padding-bottom: 5px;
  border-bottom: 1px #292826 solid;
  text-transform: uppercase;
  cursor: pointer;
}

.order-info-holder {
  display: flex;
  align-items: center;
  margin-top: -20px;
}

.order-info-holder span:nth-child(2n-1) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #78736a;
  margin-right: 10px;
}

.order-info-holder span:nth-child(2n + 0) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #292826;
  margin-right: 30px;
  margin-top: -3px;
}

.order-details-table {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.order-details-table tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff;
  padding-block: 20px;
}

.order-details-table th {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  text-align: end;
  flex: 1;
}

.order-details-table th:nth-child(1) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  text-align: start;
  flex: 1;
}

.order-details-table td {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #292826;
  flex: 1;
  text-align: end;
}

.order-details-table td:nth-child(1) {
  flex: 2.5;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #292826;
  flex: 1;
  text-align: start;
}

.order-bill-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.order-bill-box-1 {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.order-bill-box-1 td {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  flex: 1;
}

.order-bill-box-1 td:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #292826;
}

.order-bill-box-1 tr {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-block: 20px;
}

.order-bill-box-2 {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.order-bill-box-2 tr {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-block: 20px;
}

.order-bill-box-2 td {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  flex: 1;
}

.order-bill-box-2 td:nth-child(2) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #292826;
}

.order-detail-separator {
  height: 0.5px;
  width: 100%;
  background-color: #ffffff;
}
