.footer-container {
  background: #f8f8f6;
  padding: 50px;
}

.footer-top-content {
  display: flex;
}

.logo-and-contents {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.logo-and-contents p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #292826;
  width: 80%;
  margin: 15px 0px;
}

.social-icons-container {
  width: 160px;
}

.links {
  display: flex;
  flex: 2;
  justify-content: space-around;
}

.links h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 0px;
}

.links ul {
  list-style: none;
  padding: 0px;
}

.links li {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #292826;
  cursor: pointer;
}

.subscription {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.social-icons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscription h3 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 0px;
}

.subscription p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #292826;
  margin: 20px 0px 20px;
}
.subscription span {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #602714;
}

.input-container {
  border-bottom: 1px solid #797171;
  display: flex;
  background-color: #f8f8f6;
  align-items: center;
  justify-content: space-between;
}

.input-container input {
  border: none;
  background-color: #f8f8f6;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #292826;
  outline: none;
  width: 90%;
}

.footer-bottom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.footer-bottom-content div {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #292826;
}

.footer-bottom-content div span {
  cursor: pointer;
}
.blaze-water-mark {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #292826;
}
