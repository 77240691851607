.faq-banner-container {
  display: flex;
  align-items: center;
}

.faq-banner-container img {
  width: 100%;
  position: relative;
}

.faq-banner-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  width: 50%;
  margin-left: 50px;
}

.faq-banner-content h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  text-transform: uppercase;
}

.faq-banner-content p {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  margin: 20px 0px 5px;
}

.faq-contact-info {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 50px;
  color: #000000;
  margin-bottom: 20px;
}
