.news-logos-container {
  background: #f8f8f6;
  padding: 40px 0px;
  border: 1px solid #f2f2e6;
}

.logo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.logo-item img {
  width: 50%;
}

.arrow-left {
  position: absolute;
  left: 0px;
}

.arrow-right {
  position: absolute;
  right: 0px;
}

.react-multi-carousel-list {
  padding: 10px;
}
