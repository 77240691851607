.contact-text-area {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-text-area label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
}

.contact-text-area textarea {
  background: #f8f8f6;
  border: 1px solid #797171;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
  resize: vertical;
  min-height: 100px;
}
