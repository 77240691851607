.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-input label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
}

.form-input input {
  outline: none;
  border-width: 0px 0px 1px 0px;
  border-bottom-color: #797171;
  border-bottom-style: groove;
  background-color: #ebe4db;
  margin: 20px 0px 20px;
  padding-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292826;
}

.form-input input::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #78736a;
}

.form-input input:-ms-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #78736a;
}

.form-input input::placeholder {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #78736a;
}

.form-input input:focus::-webkit-input-placeholder {
  color: transparent;
}
.form-input input:focus:-moz-placeholder {
  color: transparent;
}
.form-input input:focus::-moz-placeholder {
  color: transparent;
}
.form-input input:focus:-ms-input-placeholder {
  color: transparent;
}
