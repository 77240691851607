.subscription-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e3d4cb;
  padding: 50px;
}

.subscription-box-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.subscription-box-description-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.subscription-box-description-container div {
  display: flex;
  align-items: center;
}

.sub-desc {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: #292826;
  margin-top: -5px;
  margin-left: 10px;
}

.sub-desc-2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
  margin-top: -5px;
  margin-left: 5px;
}

.sub-desc-plus {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #292826;
  text-transform: uppercase;
  margin: -5px 20px 0px;
}

.subscription-box-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 50px;
}

.subscription-products-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subscription-boxes-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.subscription-cart-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.quantity-box-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscribe-button {
  margin: 30px 0px;
}
