.mini-cart-pane {
  background-color: #ebe4db;
  position: relative;
}

.slide-pane__overlay {
  z-index: 3000;
}

.slide-pane__content {
  padding: 0px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slide-pane__content::-webkit-scrollbar {
  display: none;
}

.cart-pane-main {
  padding: 40px;
}

.cart-title {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.cart-title span {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #292826;
}

.cart-title span:nth-child(2) {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #292826;
  margin-left: 10px;
  align-self: flex-start;
}

.cart-pane-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: sticky;
  flex: 1;
  bottom: 0%;
  width: 100%;
  padding: 40px 0px;
  border-top: 1px solid white;
  background-color: #ebe4db;
}
