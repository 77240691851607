.quantity-box-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.quantity-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 18px 22px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.5s ease-in;
}

.quantity-box-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 18px 22px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.p-quantity-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9c2ab;
  border: 1px solid #f9c2ab;
  border-radius: 5px;
  padding: 18px 22px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.5s ease-in;
}

.p-quantity-box-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 18px 22px;
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.p-quantity-box-inactive:hover {
  background: #f9c2ab1f;
}

.quantity-box-inactive:hover {
  background-color: #ffffff1f;
}

.quantity {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #292826;
}

.months-text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #292826;
  margin-top: 5px;
}

.offer-star {
  position: absolute;
  top: -20%;
  right: -15%;
}
.offer-value {
  position: absolute;
  top: 20%;
  right: 15%;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
}
