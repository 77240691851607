.left-pane-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px;
}

.left-pane-container h1 {
  font-family: "Lustria", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #292826;
}

.left-pane-container div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 15px;
  cursor: pointer;
}

.pane-section-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #78736a;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
}

.active-pane {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  transition: color 0.3s ease-in;
  color: #292826;
}

.left-pane-container div:hover .pane-section-title {
  color: #292826c0;
}
